$max-width: 85.625rem;
$max-width-footer: 45rem;

$break-desktop: 40rem;
$break-desktop-header: 52rem;
$break-pages-with-header-bg: 45rem; 




$bg-brand: #035bc5;
$bg-brand-pale: #2ec4e9;
$bg-brand-light: #050505;
$bg-secondary: #79B927;
$bg-secondary-light: #79B927;
$bg-secondary-light: lighten($bg-secondary, 20%); 
$bg-gray: #bbb;
$bg-gray-light: lighten($bg-gray, 15%);
$bg-warning: #ff5622;

$font-color-secondary: #79B927;
$font-color-pale: #fffcff;
$font-color-link: #005ece;
$font-color-link: #035bc5;
$font-color-placeholder: #39b3e6;



$bg-brand: #035bc5;
$bg-brand-pale: #035bc5;
$bg-brand-light: #dff3fa;
$bg-secondary: #355e01;
$bg-secondary-light: #355e01;
$bg-secondary-light: lighten($bg-secondary, 20%); 
$bg-gray: #bbb;
$bg-gray-light: lighten($bg-gray, 15%);
$bg-warning: #ff5622;
$bg-footer-icon: #dff3fa;

$font-color-secondary: #79B927;
$font-color-pale: #fffcff;
$font-color-link: #004395;
$font-color-menu-social: #ffffff;


$line-height: 1.75rem;